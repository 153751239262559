dmx.Component('summernote', {

    constructor: function(node, parent) {
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onInit = this.onInit.bind(this);
        this.onMediaDelete = this.onMediaDelete.bind(this);
        this.setValue = this.setValue.bind(this);

        this.config = {
            popover: $.summernote.options.popover
        };

        this.callbacks = { callbacks: {
            onBlur: this.onBlur,
            onChange: this.onChange,
            onEnter: this.onEnter,
            onFocus: this.onFocus,
            onInit: this.onInit,
            onMediaDelete: this.onMediaDelete,
        }};

        this.plugins = {};
        this.buttons = { buttons: {}};
        
        dmx.BaseComponent.call(this, node, parent);
    },

    initialData: {
        disabled: false,
        value: '',
    },

    attributes: {
        'value': {
            type: String,
            default: ''
        },

        'disabled': {
            type: Boolean,
            default: false
        },

        'config': {
            type: Object,
            default: {}
        },

        'height': {
            type: Number,
            default: null
        },

        'min-height': {
            type: Number,
            default: null
        },

        'max-height': {
            type: Number,
            default: null
        },

        'autofocus': {
            type: Boolean,
            default: false
        },

        'lang': {
            type: String,
            default: 'en-US'
        },

        'air-mode': {
            type: Boolean,
            default: false
        },

        'toolbar': {
            type: Array,
            default: null // use default from summernote (https://summernote.org/deep-dive/#custom-toolbar-popover)
        },

        'toolbar-image': {
            type: Array,
            default: null // use default from summernote (popover:{image:[...]})
        },

        'toolbar-link': {
            type: Array,
            default: null // use default from summernote (popover:{link:[...]})
        },

        'toolbar-table': {
            type: Array,
            default: null // use default from summernote (popover:{table:[...]})
        },

        'toolbar-air': {
            type: Array,
            default: null // use default from summernote (popover:{air:[...]})
        },

        'blockquote-breaking-level': { // https://summernote.org/deep-dive/#blockquote-breaking-level
            type: Number,
            default: 2
        },

        'style-tags': { // https://summernote.org/deep-dive/#custom-styles
            type: Array,
            default: null
        },

        'font-names': { // https://summernote.org/deep-dive/#custom-fontnames
            type: Array,
            default: null
        },

        'font-names-ignore-check': { // https://summernote.org/deep-dive/#custom-fontnames
            type: Array,
            default: null
        },

        'font-size-units': { // https://summernote.org/deep-dive/#custom-font-size-units
            type: Array,
            default: null
        },

        'line-heights': { // https://summernote.org/deep-dive/#custom-line-heights
            type: Array,
            default: null
        },

        'placeholder': {
            type: String,
            default: null
        },

        'dialogs-in-body': {
            type: Boolean,
            default: false
        },

        'dialogs-fade': {
            type: Boolean,
            default: false
        },

        'disable-drop': {
            type: Boolean,
            default: false
        },

        'disable-shortcuts': {
            type: Boolean,
            default: false
        },

        'disable-tab': {
            type: Boolean,
            default: false
        },

        'disable-spellcheck': {
            type: Boolean,
            default: false
        },

        'disable-grammar': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        disable: function() {
            this.editor.invoke('disable');
            this.set('disabled', true);
        },

        empty: function() {
            this.editor.invoke('empty');
        },
        
        enable: function() {
            this.editor.invoke('enable');
            this.set('disabled', false);
        },

        insertText: function(str) {
            this.editor.invoke('insertText', str);
        },

        pasteHTML: function(str) {
            this.editor.invoke('pasteHTML', str);
        },

        redo: function() {
            this.editor.invoke('redo');
        },

        reset: function() {
            this.editor.invoke('reset');
        },

        setValue: function(value) {
            this.setValue(value);
        },

        undo: function() {
            this.editor.invoke('undo');
        },

        status: function(message) {
            this.editor.layoutInfo.editor.find('.note-status-output').html(message);
        },

        info: function(message) {
            this.editor.layoutInfo.editor.find('.note-status-output').html('<div class="alert alert-info">' + message + '</div>');
        },

        success: function(message) {
            this.editor.layoutInfo.editor.find('.note-status-output').html('<div class="alert alert-success">' + message + '</div>');
        },

        warning: function(message) {
            this.editor.layoutInfo.editor.find('.note-status-output').html('<div class="alert alert-warning">' + message + '</div>');
        },

        danger: function(message) {
            this.editor.layoutInfo.editor.find('.note-status-output').html('<div class="alert alert-danger">' + message + '</div>');
        },

        invoke: function(action, arg) {
            this.editor.invoke(action, arg);
        },
    },

    events: {
        blur: Event,
        change: Event,
        changed: Event,
        enter: Event,
        focus: Event,
        init: Event,
        input: Event,
        updated: Event,
        buttonclick: Event,
        mediadelete: Event
    },

    onBlur: function() {
        if (this.orgValue != this.editor.code()) {
            this.dispatchEvent('change');
            var self = this;
            dmx.nextTick(function() {
                self.dispatchEvent('changed');
            });
        }
        this.dispatchEvent('blur');
    },

    onChange: function() {
        this.updated();
        this.dispatchEvent('input');
    },

    onEnter: function() {
        this.dispatchEvent('enter');
    },

    onFocus: function() {
        this.orgValue = this.editor.code();
        this.dispatchEvent('focus');
    },

    onInit: function() {
        this.dispatchEvent('init');
    },

    onMediaDelete: function(target) {
        var src = $(target[0]).attr('src');
        this.dispatchEvent('mediadelete', null, { src: src });
    },

    toCamelCase: function(str) {
        return str.replace(/-(\w)/g, function(a, b) {
            return b.toUpperCase();
        });
    },

    $parseAttributes: function(node) {
        var self = this;

        dmx.BaseComponent.prototype.$parseAttributes.call(this, node);

        dmx.dom.getAttributes(node).forEach(function(attr) {
            if (attr.name == 'plugin') {
                self.$addBinding(attr.value, function(value) {
                    if (value) {
                        self.plugins[this.toCamelCase(attr.argument)] = $.extend({}, $.summernote.options[attr.argument], value);
                    } else {
                        delete self.plugins[attr.argument];
                    }

                    self.plugins.updated = true;
                });
            }

            if (attr.name == 'button') {
                self.$addBinding(attr.value, function(value) {
                    if (value && value.icon) {
                        var name = this.toCamelCase(attr.argument);

                        self.buttons.buttons[name] = function(context) {
                            var ui = $.summernote.ui;

                            var button = ui.button({
                                contents: '<i class="' + value.icon + '"/>',
                                tooltip: value.tooltip || '',
                                click: function() {
                                    if (typeof value.click == 'string') {
                                        dmx.parse(value.click, self);
                                    }

                                    self.dispatchEvent('buttonclick', null, {
                                        editor: self.name,
                                        button: name
                                    });
                                }
                            });

                            return button.render();
                        };

                        self.buttons.updated = true;
                    }
                });
            }
        });
    },

    render: function(node) {
        this.$node = node;
    },

    mounted: function() {
        var value = this.$node.tagName == 'TEXTAREA' ? this.$node.value.trim() : this.$node.innerHTML.trim();

        if (value.indexOf('{{') !== -1) {
            this.$addBinding(value, this.setValue);
        }

        this.update({});
    },

    update: function(props) {
        if (this.plugins.updated || this.buttons.updated || !dmx.equal(props, this.props)) {
            delete this.plugins.updated;
            delete this.buttons.updated;

            if (props.value != this.props.value) {
                this.setValue(this.props.value);
            }

            if (this.editor) {
                this.editor.destroy();
                this.editor = null;
            }

            if (!this.isDelayed) {
                this.isDelayed = true;
                dmx.nextTick(function() {
                    this.isDelayed = false;
                    this.initEditor();
                }, this);
            }
        }
    },

    updated: function() {
        var oldValue = this.data.value;

        if (this.editor) {
            if ($(this.$node).summernote('isEmpty')) {
                this.set('value', '');
            } else {
                this.set('value', this.editor.code());
            }
        } else {
            var value = this.$node.tagName == 'TEXTAREA' ? this.$node.value.trim() : this.$node.innerHTML.trim();
            this.set('value', value);
        }

        if (!this.updating && oldValue != this.data.value) {
            this.isUpdated = true;
            dmx.nextTick(function() {
                this.isUpdated = false;
                this.dispatchEvent('updated');
            }, this);
        }
    },

    beforeDestroy: function() {
        this.editor.destroy();
        this.editor = null;
    },

    setValue: function(value) {
        if (this.editor) {
            this.editor.reset();
            if (value) {
                this.editor.code(value);
            }
        } else if (this.$node.tagName == 'TEXTAREA') {
            this.$node.value = value;
        } else {
            this.$node.innerHTML = value;
        }

        this.updated();
    },

    initEditor: function() {
        var config = dmx.clone(this.config);

        config.height = this.props['height'];
        config.minHeight = this.props['min-height'];
        config.maxHeight = this.props['max-height'];
        config.focus = this.props['autofocus'];
        config.lang = this.props['lang'];
        config.airMode = this.props['air-mode'];
        config.placeholder = this.props['placeholder'];
        config.dialogsInBody = this.props['dialogs-in-body'];
        config.dialogsFade = this.props['dialogs-fade'];
        config.disableDragAndDrop = this.props['disable-drop'];
        config.shortcuts = !this.props['disable-shortcuts'];
        config.tabDisable = this.props['disable-tab'];
        config.spellCheck = !this.props['disable-spellcheck'];
        config.disableGrammar = this.props['disable-grammar'];
        config.blockquoteBreakingLevel = this.props['blockquote-breaking-level'];

        if (Array.isArray(this.props['toolbar'])) {
            // extra filter for empty group generated in Wappler
            config.toolbar = this.props['toolbar'].filter(function(group) { return group.length; });
        }

        if (Array.isArray(this.props['toolbar-image'])) {
            config.popover = config.popover || {};
            config.popover.image = this.props['toolbar-image'].filter(function(group) { return group.length; });
        }

        if (Array.isArray(this.props['toolbar-link'])) {
            config.popover = config.popover || {};
            config.popover.link = this.props['toolbar-link'].filter(function(group) { return group.length; });
        }

        if (Array.isArray(this.props['toolbar-table'])) {
            config.popover = config.popover || {};
            config.popover.table = this.props['toolbar-table'].filter(function(group) { return group.length; });
        }

        if (Array.isArray(this.props['toolbar-air'])) {
            config.popover = config.popover || {};
            config.popover.air = this.props['toolbar-air'].filter(function(group) { return group.length; });
        }

        if (Array.isArray(this.props['style-tags'])) {
            config.styleTags = this.props['style-tags'];
        }

        if (Array.isArray(this.props['font-names'])) {
            config.fontNames = this.props['font-names'];
        }

        if (Array.isArray(this.props['font-names-ignore-check'])) {
            config.fontNamesIgnoreCheck = this.props['font-names-ignore-check'];
        }

        if (Array.isArray(this.props['font-size-units'])) {
            config.fontSizeUnits = this.props['font-size-units'];
        }

        if (Array.isArray(this.props['line-heights'])) {
            config.lineHeights = this.props['line-heights'];
        }

        if (!$.summernote.lang[config.lang]) {
            console.error('Summernote "' + config.lang + '" lang file must be included.')
        }

        $.extend(true, config, this.props.config, this.plugins, this.buttons, this.callbacks);

        $(this.$node).summernote(config);
        
        this.editor = $(this.$node).data('summernote');

        this.editor.layoutInfo.statusbar.find('.note-status-output').remove();

        if (this.props.disabled) {
            this.editor.disable();
            this.set('disabled', true);
        }

        if (this.$node.hasAttribute('dmxDomId')) {
            this.editor.layoutInfo.editor.attr('dmxDomId', this.$node.getAttribute('dmxDomId'));
        }
    },



});
